import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import restSound from "./assets/sounds/pause.mp3";
import startSound from "./assets/sounds/start.mp3";
import minuteSound from "./assets/sounds/minute.mp3";
import endSound from "./assets/sounds/finished.mp3";

const Timer = ({ stations, repeats, onReset }) => {
  const [currentRound, setCurrentRound] = useState(1);
  const [currentStation, setCurrentStation] = useState(1);
  const [timeLeft, setTimeLeft] = useState(120); // Standard: 2 minutter træning
  const [isWorkout, setIsWorkout] = useState(true);
  const [isPaused, setIsPaused] = useState(false);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedAction, setSelectedAction] = useState(null);

  const timerRef = useRef(null);
  const wakeLockRef = useRef(null); // Ref to manage Wake Lock

  const restAudio = useMemo(() => new Audio(restSound), []);
  const startAudio = useMemo(() => new Audio(startSound), []);
  const endAudio = useMemo(() => new Audio(endSound), []);
  const minuteAudio = useMemo(() => new Audio(minuteSound), []);

  const playAudioOnce = useCallback((audio) => {
    if (isAudioPlaying) return;
    setIsAudioPlaying(true);
    audio.currentTime = 0;
    audio.play();
    audio.onended = () => setIsAudioPlaying(false);
  }, [isAudioPlaying]);

  const nextStep = useCallback(() => {
    if (isWorkout) {
      playAudioOnce(restAudio);
      setIsWorkout(false);
      setTimeLeft(60); // Pause på 1 minut
    } else {
      if (currentStation < stations) {
        setCurrentStation(currentStation + 1);
      } else {
        if (currentRound < repeats) {
          setCurrentRound(currentRound + 1);
          setCurrentStation(1);
        } else {
          playAudioOnce(endAudio);
          onReset();
          return;
        }
      }
      playAudioOnce(startAudio);
      setIsWorkout(true);
      setTimeLeft(120); // Træning på 2 minutter
    }
  }, [isWorkout, currentStation, stations, currentRound, repeats, restAudio, startAudio, endAudio, playAudioOnce, onReset]);

  // Effect to handle Wake Lock
  useEffect(() => {
    const requestWakeLock = async () => {
      try {
        if ("wakeLock" in navigator) {
          wakeLockRef.current = await navigator.wakeLock.request("screen");
        }
      } catch (err) {
        console.error("Wake Lock request failed:", err);
      }
    };

    requestWakeLock();

    return () => {
      if (wakeLockRef.current) {
        wakeLockRef.current.release().then(() => {
          wakeLockRef.current = null;
        });
      }
    };
  }, []);

  useEffect(() => {
    if (isPaused) {
      clearInterval(timerRef.current);
      return;
    }

    timerRef.current = setInterval(() => {
      setTimeLeft((prev) => {
        if (prev === 0) {
          nextStep();
          return 0; // Reset to 0 to avoid extra counts.
        }
        if (prev === 62 && isWorkout) {
          playAudioOnce(minuteAudio);
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timerRef.current); // Cleanup on unmount or pause.
  }, [isPaused, nextStep, isWorkout, playAudioOnce, minuteAudio]);

  const togglePause = () => setIsPaused(!isPaused);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const handleSkip = () => {
    setSelectedAction("skip");
    setShowPopup(true);
  };

  const handlePause = () => {
    setSelectedAction("pause");
    setShowPopup(true);
  };

  const handleReset = () => {
    setSelectedAction("reset");
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
    setSelectedAction(null);
  };

  const confirmAction = () => {
    if (selectedAction === "pause") {
      togglePause();
    } else if (selectedAction === "skip") {
      nextStep();
    } else if (selectedAction === "reset") {
      onReset();
    }
    closePopup();
  };

  const progressBarWidth = isWorkout ? ((120 - timeLeft) / 120) * 100 : ((60 - timeLeft) / 60) * 100;

  return (
    <div className="timer-container">
      <div className="progress-bar-container">
        <div className="progress-bar" style={{ width: `${progressBarWidth}%` }}></div>
      </div>

      <h2 style={{ color: isWorkout ? "red" : "black" }}>Work it out!</h2>

      <div className="timer-display">{formatTime(timeLeft)}</div>
      <h2>Runde: {currentStation}/{stations}</h2>
      <h2>Omgang: {currentRound}/{repeats}</h2>
      <div className="button-group">
        <button className="button" onClick={handlePause}>
          {isPaused ? "Genoptag" : "Pause"}
        </button>
        <button className="button" onClick={handleSkip}>
          Skip
        </button>
        <button className="button" onClick={handleReset}>
          Nulstil
        </button>
      </div>

      {showPopup && (
        <div className="popup-overlay">
          <div className="popup">
            <h3>Er du sikker?</h3>
            <p>
              {selectedAction === "pause" && "Vil du pause timeren?"}
              {selectedAction === "skip" && "Vil du springe runden over?"}
              {selectedAction === "reset" && "Vil du nulstille timeren?"}
            </p>
            <div className="popup-actions">
              <button className="button" onClick={confirmAction}>
                Ja
              </button>
              <button className="button" onClick={closePopup}>
                Nej
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Timer;
