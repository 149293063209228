import React, { useState, useMemo } from "react";
import Timer from "./Timer";
import "./App.css";
import workoutSound from "./assets/sounds/int.mp3"; // Workout lyd

function App() {
  const [stations, setStations] = useState(8); // Standard antal stationer
  const [repeats, setRepeats] = useState(1); // Standard antal gentagelser
  const [isRunning, setIsRunning] = useState(false);

  // Calculate the total workout time
  const totalWorkoutTime = useMemo(() => {
    const workoutTimePerStation = 120; // 2 minutes per station
    const restTimePerStation = 60; // 1 minute rest
    const totalStationsTime = stations * (workoutTimePerStation + restTimePerStation);
    const totalWorkoutTime = totalStationsTime * repeats;
    return totalWorkoutTime;
  }, [stations, repeats]);

  // Format time as MM:SS
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  // Function to start the timer and play the workout sound
  const startTimer = () => {
    const workoutAudio = new Audio(workoutSound); // Create a new audio object
    workoutAudio.play(); // Play workout sound
    setIsRunning(true); // Start the timer
  };

  return (
    <div className="app-container">
      {!isRunning ? (
        <div className="setup-container">
          <h1>Setup Interval Timer</h1>
          <label>
            Antal Stations:
            <input
              type="number"
              min="1"
              max="24"
              value={stations}
              onChange={(e) => setStations(Number(e.target.value))}
              className="input-box"
            />
          </label>
          <br />
          <label>
            Omgange:
            <input
              type="number"
              min="1"
              max="24"
              value={repeats}
              onChange={(e) => setRepeats(Number(e.target.value))}
              className="input-box"
            />
          </label>
          <p>Samlet tid: <strong>{formatTime(totalWorkoutTime)}</strong></p>
          <button className="button" onClick={startTimer}>
            Start Timer
          </button>
        </div>
      ) : (
        <Timer
          stations={stations}
          repeats={repeats}
          onReset={() => setIsRunning(false)}
        />
      )}
    </div>
  );
}

export default App;
